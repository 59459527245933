import Vue from "vue";
import App from "./App.vue";

import router from "./router";

Vue.config.productionTip = false;

Vue.prototype.apiUrl = "/api";

//引入ECharts
import ECharts from "vue-echarts";
import "echarts";
Vue.component("ECharts", ECharts);

import websocket from "./utils/webSocket";
Vue.prototype.$websocket = websocket;

import { Icon } from "vant";
Vue.use(Icon);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
